const categoryRegex = /\(([^)]+)\)$/;

export const createCategoryAcronym = (categoryName: string) => {
	const catNameMatches = categoryName.trim().match(categoryRegex);
	let catName = '';

	if (catNameMatches) {
		catName = catNameMatches[1];
	} else {
		categoryName = categoryName.replace(/([/-])/g, ' $1 ');

		catName = categoryName
			.split(' ') // Split the string into words
			.map((word) => word.charAt(0)) // Get the first character of each word
			.filter(
				(char) => ['/', '-'].includes(char) || char === char.toUpperCase()
			) // Filter only uppercase characters
			.join(''); // Join them to form the acronym;
	}

	// If the final acronym is less than 3 characters long, return the first 3 characters of categoryName all capitalized
	if (catName.length < 2) {
		catName = categoryName.slice(0, 3);
	}

	return catName;
};
