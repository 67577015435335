import Heading from 'components/atoms/Heading';
import { useGetDashboardOverviewQuery } from 'redux/api/vehicleIncidents';
import { TailSpin } from 'react-loader-spinner';
import Text from 'components/atoms/Text';
import DashboardCard from '../DashboardCard';
import DashboardStatusCard from '../DashboardStatusCard';
import StateTotalDonutChart from '../StateTotalDonutChart';
import Top5MonthlyIncidentsGraph from '../Top5MonthlyIncidentsGraph';
import { firstDayOfLastJuly } from 'utils/firstDayOfLastJuly';
import { formatDate } from 'utils/formatDate';
import { IIncidentCategoryBreakdownFilters } from 'types/VehicleIncidents';

export interface IDashboardIncidentsOverview {}

const DashboardIncidentsOverview: React.FC<
	IDashboardIncidentsOverview
> = () => {
	const fromDate = firstDayOfLastJuly();
	const filters: IIncidentCategoryBreakdownFilters = {
		dateFrom: formatDate(fromDate),
	};
	const { data } = useGetDashboardOverviewQuery(filters);

	return (
		<div>
			<Heading type="h1" className="mb-6">
				INCIDENTS OVERVIEW
			</Heading>
			{data ? (
				<>
					<div>
						<Heading type="h3" className="tracking-wide mb-2">
							{data.states.join(', ')}
						</Heading>
						<Text className="text-[14px]">
							{data.branches.map((branch) => branch.name).join(', ')}
						</Text>
					</div>
					<div className="flex mt-8 space-x-[30px] items-stretch max-h-[700px]">
						<div className="flex flex-col basis-2/5 max-w-[620px] min-w-[472px] items-stretch">
							<DashboardCard
								className="mb-[30px]"
								title="Incidents in Current Financial Year"
							>
								<Text className="text-[22px] font-semibold mb-2">
									<span className="text-gray-900">
										{data.currentYear.incident.totalOpen}
									</span>{' '}
									Open Incident Reports
								</Text>
								<progress
									id="totalInYear"
									value={data.currentYear.incident.totalOpen}
									max={data.currentYear.incident.total}
									className="w-full percentbar"
								/>
								<Text className="text-[14px]">
									Total of {data.currentYear.incident.total} incident reports.
								</Text>
							</DashboardCard>
							<DashboardCard
								className="basis-1/3 overflow-y-auto grow"
								total={data.incidentStatusData.total}
								dividerColor="border-scorpion-200"
								title="Incidents by Branch"
								contentMaxHeight="max-h-[600px]"
							>
								<DashboardStatusCard
									incidentPath="incidents"
									branches={data.incidentStatusData.branches}
								/>
							</DashboardCard>
						</div>
						<div className="flex flex-col basis-1/5 items-stretch max-w-[650px]">
							<DashboardCard
								className="w-full items-stretch grow"
								title="State Totals for Current Financial Year"
								contentMaxHeight="flex-col justify-center items-center"
							>
								<StateTotalDonutChart states={data.stateTotals} />
							</DashboardCard>
						</div>
						<div className="flex flex-col basis-2/5 items-stretch grow">
							<DashboardCard
								className="w-full items-stretch grow"
								title="Top 5 Branches - Monthly"
							>
								<Top5MonthlyIncidentsGraph monthData={data.currentMonthData} />
							</DashboardCard>
						</div>
					</div>
				</>
			) : (
				<div className="flex flex-col justify-center h-full">
					<TailSpin
						height="25"
						width="25"
						ariaLabel="tail-spin-loading"
						color="#FE9F00"
						wrapperClass="flex mt-2"
						radius={2}
						visible
					/>
				</div>
			)}
		</div>
	);
};

export default DashboardIncidentsOverview;
