import {
	IIncidentMechanismSummary,
	IIncidentCategoryBreakdownFilters,
} from 'types/VehicleIncidents';
import { useGetDashboardBreakdownByMechanismQuery } from 'redux/api/vehicleIncidents';
import DashboardIncidentMechanismBreakdownCard from '../DashboardIncidentMechanismBreakdownCard';
import DashboardCard from '../DashboardCard';
import Heading from 'components/atoms/Heading';
import IncidentMechanismsAtAGlanceGraph from '../IncidentMechanismsAtAGlanceGraph';
import { firstDayOfLastJuly } from 'utils/firstDayOfLastJuly';
import { formatDate } from 'utils/formatDate';

export interface IDashboardIncidentsMainMechanism {
	pdfRef: React.RefObject<HTMLDivElement>;
	renderingPdf: boolean;
}

const DashboardIncidentsMainMechanism: React.FC<
	IDashboardIncidentsMainMechanism
> = ({ pdfRef, renderingPdf }) => {
	const fromDate = firstDayOfLastJuly();
	const filters: IIncidentCategoryBreakdownFilters = {
		dateFrom: formatDate(fromDate),
	};
	const { data } = useGetDashboardBreakdownByMechanismQuery(filters);
	const mechanisms: IIncidentMechanismSummary[] = data?.mechanisms || [];
	const graphMechanisms = data?.graphMechanisms || [];
	const renderClasses = renderingPdf ? '' : 'max-h-[600px] overflow-y-auto';

	return (
		<div className={`pt-6 ${renderingPdf ? '' : 'max-h-[700px]'}`} ref={pdfRef}>
			<Heading type="h3" className="mb-4 text-[24px]">
				All Mechanisms
			</Heading>
			<div className="flex space-x-[30px]">
				<div className="flex flex-col basis-2/5 min-w-[472px] items-stretch">
					<DashboardCard
						className={`basis-1/3 ${renderClasses} grow`}
						title="Incident Mechanisms"
						total={data?.total}
						dividerColor="border-grey-200"
					>
						<DashboardIncidentMechanismBreakdownCard
							mechanisms={mechanisms}
							incidentPath="incidents"
						/>
					</DashboardCard>
				</div>
				<div className="flex flex-col basis-3/5 min-w-[472px] items-stretch">
					<DashboardCard
						className="basis-2/3 max-h-[600px] min-h-[600px] overflow-y-auto"
						title="Mechanisms at a Glance"
						dividerColor="border-grey-200"
					>
						<IncidentMechanismsAtAGlanceGraph mechanismData={graphMechanisms} />
					</DashboardCard>
				</div>
			</div>
		</div>
	);
};

export default DashboardIncidentsMainMechanism;
