import React from 'react';
import DashboardStatusButton from 'components/atoms/DashboardStatusButton';
import { Link } from 'react-router-dom';
import { IIncidentSeveritySummary } from 'types/VehicleIncidents';
import { createCategoryAcronym } from 'utils/createCategoryAcronym';

export interface IDashboardIncidentSeverityBreakdownCard {
	className?: string;
	severities: IIncidentSeveritySummary[] | undefined;
	incidentPath?: string;
	getExtraFilters?: () => { [key: string]: string | number };
}

const DashboardIncidentSeverityBreakdownCard: React.FC<
	IDashboardIncidentSeverityBreakdownCard
> = ({
	className = '',
	severities,
	incidentPath,
	getExtraFilters = () => ({}),
}) => {
	//@ts-ignore
	const extraFilters: { [key: string]: string | number } = {
		...getExtraFilters(),
	};

	//@ts-ignore
	const urlString = new URLSearchParams(extraFilters).toString();

	return (
		<table className={`${className} px-8 w-full`}>
			<thead className="border-b border-gray-200">
				<tr>
					<th className="text-secondary font-normal text-left pr-8 pb-3">
						Severity
					</th>
					<th className="text-secondary font-normal text-left pr-8 pb-3">
						Total
					</th>
					<th className="text-secondary font-normal text-left pr-8 pb-3">
						Status
					</th>
					<th className="text-secondary font-normal text-left pr-8 pb-3">
						Categories
					</th>
					<th className="text-secondary font-normal text-left pr-8 pb-3">
						Critical Action
					</th>
				</tr>
			</thead>
			<tbody>
				{severities?.map((severity: IIncidentSeveritySummary) => {
					const incident_total = (
						<Link
							to={`/${incidentPath}?severity=${severity.name}&${urlString}`}
						>
							{severity.statistics.total}
						</Link>
					);

					return (
						<tr
							className="border-b border-gray-200 py-4 font-normal font-scorpion-200"
							key={severity.id}
						>
							<td className="pr-8 py-5 font-inter text-scorpion-500">
								{severity.name}
							</td>
							<td className="pr-8 py-5 underline font-inter text-scorpion-500">
								{incident_total}
							</td>
							<td>
								<div className="pr-8 flex flex-row flex-wrap gap-y-2 gap-x-2 py-3">
									{['new', 'draft', 'submitted', 'open', 'closed'].map(
										(status) => {
											// @ts-ignore trust me bro
											if (severity.statistics[status] === 0) {
												return null;
											}

											const extraLinkQuery = `severity=${severity.name}&status=${status}&${urlString}`;

											return (
												<DashboardStatusButton
													key={`status-${severity.id}-${status}`}
													status={status}
													modulePath={incidentPath}
													// @ts-ignore trust me bro x2
													total={severity.statistics[status]}
													extraLinkQuery={extraLinkQuery}
													totalBrackets={true}
												/>
											);
										}
									)}
								</div>
							</td>
							<td>
								<div className="pr-8 flex flex-row flex-wrap gap-y-2 gap-x-2 py-3">
									{Object.keys(severity.statistics.categories).map((cat) => {
										const category = severity.statistics.categories[cat];
										if (category === 0) {
											return null;
										}

										const extraLinkQuery = `severity=${severity.name}&category=${cat}&${urlString}`;

										return (
											<DashboardStatusButton
												key={`category-${severity.id}-${cat}`}
												status={createCategoryAcronym(cat)}
												modulePath={incidentPath}
												total={category}
												extraLinkQuery={extraLinkQuery}
												totalBrackets={true}
											/>
										);
									})}
								</div>
							</td>
							<td>
								<div className="pr-8 flex flex-row flex-wrap gap-y-2 gap-x-2 py-3">
									{severity.statistics.actions['open'] > 0 && (
										<DashboardStatusButton
											status="Open"
											modulePath={incidentPath}
											total={severity.statistics.actions['open']}
											extraLinkQuery={`severity=${severity.name}&correctiveActions=open&${urlString}`}
											totalBrackets={true}
										/>
									)}
									{severity.statistics.actions['overdue'] > 0 && (
										<DashboardStatusButton
											status="Overdue"
											modulePath={incidentPath}
											total={severity.statistics.actions['overdue']}
											extraLinkQuery={`severity=${severity.name}&correctiveActions=open&${urlString}`}
											totalBrackets={true}
										/>
									)}
									{severity.statistics.actions['completed'] > 0 && (
										<DashboardStatusButton
											status="Complete"
											modulePath={incidentPath}
											total={severity.statistics.actions['completed']}
											extraLinkQuery={`severity=${severity.name}&correctiveActions=open&${urlString}`}
											totalBrackets={true}
										/>
									)}
								</div>
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default DashboardIncidentSeverityBreakdownCard;
