import MainLayoutV2 from 'components/templates/MainLayout/MainLayoutV2';
import DashboardIncidents from 'components/molecules/DashboardIncidents';
import { useGetPermissionsMutation } from 'redux/api/vehicleIncidents';
import { useEffect } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { updateBasicPermissions } from 'redux/slices/vehicleIncidentsSlice';

const DashboardV2 = () => {
	const dispatch = useAppDispatch();
	const [getPermissions, { data: permissionsData }] =
		useGetPermissionsMutation();

	useEffect(() => {
		if (permissionsData) {
			dispatch(updateBasicPermissions(permissionsData));
		}
		// eslint-disable-next-line
	}, [permissionsData]);

	useEffect(() => {
		getPermissions();
		// eslint-disable-next-line
	}, []);

	return (
		<MainLayoutV2 url="dashboard">
			<DashboardIncidents />
		</MainLayoutV2>
	);
};

export default DashboardV2;
