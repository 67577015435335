import Divider from 'components/atoms/Divider';
import DashboardIncidentsMain from '../DashboardIncidentsMain/DashboardIncidentsMain';
import DashboardIncidentsOverview from '../DashboardIncidentsOverview/DashboardIncidentsOverview';
import DashboardIncidentsSearchBar from '../DashboardIncidentsSearchBar';
import {
	InitialDashboardIncidentFilters,
	IFiltersDashboardIncidents,
} from 'types/VehicleIncidents';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { QuicklinkOptions } from '../DashboardIncidentsQuicklinks/DashboardIncidentsQuicklinks';

export interface IDashboardIncidents {}

const DashboardIncidents: React.FC<IDashboardIncidents> = () => {
	const branches = useAppSelector(
		(state) => state.vehicleIncident.basicPermissionsBranches
	);
	const states = useAppSelector(
		(state) => state.vehicleIncident.basicPermissionsStates
	);

	const [filters, setFiltersRaw] = useState(InitialDashboardIncidentFilters);
	const [filtersOpen, setFiltersOpen] = useState(false);
	const [filtersHaveChanged, setFiltersHaveChanged] = useState(false);
	const setFilters = (filters: IFiltersDashboardIncidents) => {
		setFiltersRaw(filters);
		setFiltersHaveChanged(true);
	};
	const [activeQuicklink, setActiveQuickLink] =
		useState<QuicklinkOptions>('all');

	useEffect(() => {
		const stateData = states;
		const branchData = branches;

		const filterState = JSON.parse(JSON.stringify(filters));
		filterState['state'] = stateData;
		filterState['branch'] = branchData;
		setFiltersRaw(filterState);
		// eslint-disable-next-line
	}, [branches, states]);

	const resetFilters = () => {
		setFiltersRaw({
			...InitialDashboardIncidentFilters,
			state: states,
			branch: branches,
		});
		setFiltersHaveChanged(false);
		setActiveQuickLink('all');
	};

	return (
		<main className="h-full flex">
			<DashboardIncidentsSearchBar
				filters={filters}
				setFilters={setFilters}
				open={filtersOpen}
				setOpen={setFiltersOpen}
				resetFilters={resetFilters}
			/>
			<div className="bg-gray-200 px-12 pb-12 pt-6 overflow-y-scroll flex-auto">
				<div className="pt-[40px] pb-20">
					{!filtersOpen && (
						<>
							<DashboardIncidentsOverview />
							<Divider
								className="mt-[64px] mb-16 -mx-12"
								color="!border-t-[4px] border-orange-500"
							/>
						</>
					)}
					<DashboardIncidentsMain
						onOpenFilters={() => {
							setFiltersOpen(!filtersOpen);
						}}
						filters={filters}
						filtersHaveChanged={filtersHaveChanged}
						activeQuicklink={activeQuicklink}
						setActiveQuickLink={setActiveQuickLink}
						filtersOpen={filtersOpen}
					/>
				</div>
			</div>
		</main>
	);
};

export default DashboardIncidents;
