import React from 'react';
import { Link } from 'react-router-dom';

export interface IDashboardStatusButton {
	className?: string;
	status:
		| 'open'
		| 'closed'
		| 'draft'
		| 'low'
		| 'medium'
		| 'high'
		| 'severe'
		| 'extreme'
		| 'completed'
		| 'submitted'
		| 'not opened'
		| 'duplicate'
		| 'nuisance'
		| 'new'
		| '1'
		| '2'
		| '3'
		| '4'
		| '5'
		| string;
	branch?: string;
	category?: string;
	modulePath?: string;
	total?: number;
	extraLinkQuery?: string;
	totalBrackets?: boolean;
}

const DashboardStatusButton: React.FC<IDashboardStatusButton> = ({
	status,
	modulePath = '',
	total,
	className = '',
	extraLinkQuery = '',
	totalBrackets = false,
}) => {
	const textStyle = {
		open: 'text-[#DC3F4F]',
		closed: 'text-[#32BFA7]',
		draft: 'text-[#3D62C0]',
		low: 'text-secondary',
		medium: 'text-secondary',
		high: 'text-secondary',
		severe: 'text-secondary',
		extreme: 'text-secondary',
		completed: 'text-[#32BFA7]',
		submitted: 'text-[#3D62C0]',
		duplicate: 'text-secondary',
		nuisance: 'text-secondary',
		'not opened': 'text-[#DC3F4F]',
		new: 'text-[#3D62C0]',
		'1': 'text-secondary',
		'2': 'text-secondary',
		'3': 'text-secondary',
		'4': 'text-secondary',
		'5': 'text-secondary',
	};

	const borderStyle = {
		open: 'border-[#DC3F4F] bg-[#feeeee]',
		closed: 'border-[#32bfa7] bg-[#eaf8f6]',
		draft: 'border-[#3D62C0] bg-[#d8dff2]',
		low: 'border-secondary bg-[#EEE]',
		medium: 'border-secondary bg-[#EEE]',
		high: 'border-secondary bg-[#EEE]',
		severe: 'border-secondary bg-[#EEE]',
		extreme: 'border-secondary bg-[#EEE]',
		completed: 'border-[#32BFA7] bg-[#eaf8f6]',
		submitted: 'border-[#3D62C0] bg-[#d8dff2]',
		duplicate: 'border-secondary bg-[#EEE]',
		nuisance: 'border-secondary bg-[#EEE]',
		'not opened': 'border-[#DC3F4F]',
		new: 'border-[#3D62C0] bg-[#d8dff2]',
		'1': 'border-secondary bg-[#EEE]',
		'2': 'border-secondary bg-[#EEE]',
		'3': 'border-secondary bg-[#EEE]',
		'4': 'border-secondary bg-[#EEE]',
		'5': 'border-secondary bg-[#EEE]',
	};
	let totalString = `${status} ${total && total}`;

	if (totalBrackets) {
		totalString = `${status} (${total && total})`;
	}

	const uriSafeQuery = encodeURI(extraLinkQuery);

	var incident_link: JSX.Element | string = (
		<Link to={`/${modulePath}?${uriSafeQuery}`}>{totalString}</Link>
	);

	//@ts-ignore
	const useBorderStyle = borderStyle[status]
		? //@ts-ignore
		  borderStyle[status]
		: borderStyle['1'];
	//@ts-ignore
	const useTextStyle = textStyle[status] ? textStyle[status] : textStyle['1'];

	return (
		<div
			className={`max-w-[125px] flex justify-center items-center bg-white !rounded-3xl border rounded-[10px] py-0.5 px-2 w-fit ${useBorderStyle} ${className}`}
		>
			<p
				className={`flex-grow-0 flex-shrink-0 uppercase tracking-[0.88px] text-xs 2xl:text-sm w-fit text-center align-middle h-full font-semibold text-[11px] 2xl:text-[11px] ${useTextStyle}`}
			>
				{incident_link}
			</p>
		</div>
	);
};

export default DashboardStatusButton;
