import { useAppSelector } from 'redux/hooks';
import DashboardIncidentsMainBranch from '../DashboardIncidentsMainBranch';
import Divider from 'components/atoms/Divider';

export interface IDashboardIncidentsMainBranches {
	pdfRef: React.RefObject<HTMLDivElement>;
	renderingPdf: boolean;
}

function getUniqueBranches(arr: { id: string; name: string }[] | undefined) {
	if (!arr) return [];
	const uniqueObjects: any[] = [];
	const seen = new Set();

	arr.forEach((obj) => {
		const objString = JSON.stringify(obj);
		if (!seen.has(objString)) {
			seen.add(objString);
			uniqueObjects.push(obj);
		}
	});

	return uniqueObjects;
}

const DashboardIncidentsMainBranches: React.FC<
	IDashboardIncidentsMainBranches
> = ({ pdfRef, renderingPdf }) => {
	const permissions = useAppSelector((state) => state.user.permissions);
	const branches = permissions?.map((obj) => {
		return obj.branch;
	});
	const uniqueBranches = getUniqueBranches(branches);
	uniqueBranches.sort((a, b) => a.name.localeCompare(b.name));

	return (
		<div ref={pdfRef}>
			<DashboardIncidentsMainBranch
				isOpenDefault={true}
				renderingPdf={renderingPdf}
			/>
			<Divider
				className="mt-[40px] mb-[30px]"
				color="!border-t-[4px] border-orange-500"
			/>
			{uniqueBranches.map((branch) => (
				<>
					<DashboardIncidentsMainBranch
						key={branch.id}
						branch={branch}
						renderingPdf={renderingPdf}
					/>
					<Divider
						className="mt-[40px] mb-[30px]"
						color="!border-t-[4px] border-orange-500"
					/>
				</>
			))}
		</div>
	);
};

export default DashboardIncidentsMainBranches;
