import React from 'react';
import DashboardStatusButton from 'components/atoms/DashboardStatusButton';
import { IBranch } from 'types/Branches';
import { Link } from 'react-router-dom';

export interface IDashboardStatusCard {
	className?: string;
	branches: IBranch[];
	incidentPath?: string;
}

const DashboardStatusCard: React.FC<IDashboardStatusCard> = ({
	className = '',
	branches,
	incidentPath,
}) => {
	var incident_total: JSX.Element | string = '';
	return (
		<table className={`${className} px-8 w-full font-inter text-scorpion-500`}>
			<thead className="border-b border-scorpion-100">
				<tr>
					<th className="text-secondary font-semibold text-left pb-2">
						Branch
					</th>
					<th className="text-secondary font-semibold text-left pb-2 pr-4">
						Total
					</th>
					<th className="text-secondary font-semibold text-left pb-2">
						Status
					</th>
				</tr>
			</thead>
			<tbody>
				{branches.map((branch: IBranch) => {
					if (incidentPath === 'incidents') {
						incident_total = (
							<Link to={`/${incidentPath}?branch_name=${branch.name}`}>
								{branch.statistics.total}
							</Link>
						);
					} else {
						incident_total = <>{branch.statistics.total}</>;
					}
					return (
						<tr
							className="border-b border-scorpion-100 text-[14px]"
							key={branch.id}
						>
							<td className="py-4 font-normal">{branch.name}</td>
							<td className="py-4 font-normal underline">{incident_total}</td>
							<td>
								<div className="flex flex-row gap-x-2">
									{['draft', 'submitted', 'new', 'open', 'closed'].map(
										(status) => {
											const statIndex = status === 'new' ? 'newCount' : status;
											//@ts-ignore
											if (branch.statistics[statIndex] === 0) return null;

											const extraLinkQuery = `branch_name=${branch.name}&status=${status}`;
											return (
												<DashboardStatusButton
													key={`status-${branch.id}-${status}`}
													status={status}
													branch={branch.name}
													// @ts-ignore
													total={branch.statistics[statIndex]}
													modulePath={incidentPath}
													extraLinkQuery={extraLinkQuery}
												/>
											);
										}
									)}
								</div>
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default DashboardStatusCard;
