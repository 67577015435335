import React from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ChartOptions,
	TooltipItem,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { IFault, IIncident, ISurvey } from 'types/Branches';
import { IGraphCategory, IGraphCategoryBranch } from 'types/VehicleIncidents';
import { createCategoryAcronym } from 'utils/createCategoryAcronym';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

export interface IIncidentCategoriesAtAGlanceGraph {
	categoryData: IGraphCategory[] | undefined;
}

const IncidentCategoriesAtAGlanceGraph: React.FC<
	IIncidentCategoriesAtAGlanceGraph
> = ({ categoryData }) => {
	const options: ChartOptions<'bar'> = {
		plugins: {
			legend: {
				align: 'start',
				position: 'bottom',
				fullSize: true,
				labels: {
					usePointStyle: true,
					pointStyle: 'rect',
					boxHeight: 8,
					font: {
						size: 12,
					},
				},
			},
			tooltip: {
				displayColors: true,
				callbacks: {
					//removes default tooltip body information
					label() {
						return '';
					},
					afterBody(context: TooltipItem<'bar'>[]) {
						const currentCategory = categoryData?.find(
							(currentMonthData: IGraphCategory) =>
								currentMonthData.name === context[0].label
						);

						if (currentCategory) {
							const resultString = [];

							if (currentCategory.total > 0) {
								resultString.push(`Incidents: ${currentCategory.total}`);
							}

							//format and sort incidents to push to string
							const incidents = currentCategory.branches
								.map((branch: IGraphCategoryBranch) => {
									return {
										name: branch.name,
										incidents: branch.total,
									};
								})
								.filter((branch: IIncident) => branch.incidents !== 0)
								.sort(
									(a: IIncident, b: IIncident) => b.incidents - a.incidents
								);

							incidents.forEach((incident: IIncident) =>
								resultString.push(` - ${incident.name}: ${incident.incidents}`)
							);

							if (currentCategory.open > 0) {
								if (currentCategory.total > 0) {
									resultString.push('');
								}

								resultString.push(`Open: ${currentCategory.open}`);
							}

							//format and sort faults to push to string
							const faults = currentCategory.branches
								.map((branch: IGraphCategoryBranch) => {
									return {
										name: branch.name,
										faults: branch.open,
									};
								})
								.filter((branch: IFault) => branch.faults !== 0)
								.sort((a: IFault, b: IFault) => b.faults - a.faults);

							faults.forEach((fault: IFault) =>
								resultString.push(` - ${fault.name}: ${fault.faults}`)
							);

							if (currentCategory.closed > 0) {
								if (currentCategory.open > 0 || currentCategory.total > 0) {
									resultString.push('');
								}
								resultString.push(`Closed: ${currentCategory.closed}`);
							}
							//format and sort faults to push to string
							const surveys = currentCategory.branches
								.map((branch: IGraphCategoryBranch) => {
									return {
										name: branch.name,
										surveys: branch.closed,
									};
								})
								.filter((branch: ISurvey) => branch.surveys !== 0)
								.sort((a: ISurvey, b: ISurvey) => b.surveys - a.surveys);

							surveys.forEach((survey: ISurvey) =>
								resultString.push(` - ${survey.name}: ${survey.surveys}`)
							);

							return resultString;
						} else {
							return [`${context[0].dataset.label}: ${context[0].raw}`];
						}
					},
				},
				padding: {
					left: 10,
					right: 30,
					y: 10,
				},
				borderColor: 'rgba(52, 64, 84, 0.5)',
				borderWidth: 1,
				backgroundColor: '#FFFFFF',
				titleColor: '#344054',
				bodyColor: '#344054',
				bodyFont: {
					size: 11,
				},
			},
		},
		responsive: true,
		interaction: {
			mode: 'index' as const,
			intersect: false,
		},
		scales: {
			x: {
				stacked: true,
			},
			y: {
				stacked: true,
			},
		},
		maintainAspectRatio: false,
		// @ts-ignore - ChartJS types are simply incorrect
		barThickness: 24,
	};

	const data = {
		labels: categoryData?.map((category: IGraphCategory) =>
			createCategoryAcronym(category.name)
		),
		datasets: [
			{
				label: 'Incidents total',
				data: categoryData?.map((category: IGraphCategory) => category.total),
				backgroundColor: '#FAA431',
				stack: 'Stack 0',
			},
		],
	};

	return <Bar options={options} data={data} redraw={true} />;
};

export default IncidentCategoriesAtAGlanceGraph;
