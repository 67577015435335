import React from 'react';

export interface ITabLink {
	className?: string;
	children: React.ReactNode;
	onClick?: () => any;
	active?: boolean;
	disabled?: boolean;
	errorExists?: boolean;
	v2?: boolean;
	fontColor?: string;
	fontHoverColor?: string;
	fontBoldness?: string;
	fontSize?: string;
}

const TabLink: React.FC<ITabLink> = ({
	children,
	active = false,
	onClick,
	className = '',
	disabled = false,
	errorExists = false,
	v2,
	fontColor = 'text-secondary-50',
	fontHoverColor = 'text-secondary-200',
	fontBoldness = 'font-bold',
	fontSize = 'text-base',
}) => {
	const tabStyles = errorExists
		? 'text-red-500'
		: `${fontColor} hover:${fontHoverColor}`;

	return (
		<>
			{disabled ? (
				<div className=" text-primary-700 font-bold font-inter tracking-normal">
					{children}
				</div>
			) : (
				<div
					onClick={onClick}
					className={`${
						v2 ? 'pb-8' : 'pb-2'
					} flex justify-center items-center p-2 bg-transparent cursor-pointer font-inter ${fontSize} ${fontBoldness} tracking-normal
					${
						active
							? `text-primary-500 border-b-[4px] border-primary ${
									v2 ? '!pb-7' : ''
							  }`
							: `${tabStyles}`
					}
					${className}`}
				>
					{children}
				</div>
			)}
		</>
	);
};

export default TabLink;
