import {
	IIncidentCategoryBreakdownFilters,
	IIncidentCategorySummary,
} from 'types/VehicleIncidents';
import { useGetDashboardBreakdownQuery } from 'redux/api/vehicleIncidents';
import DashboardIncidentCategoryBreakdownCard from '../DashboardIncidentCategoryBreakdownCard';
import DashboardCard from '../DashboardCard';
import IncidentCategoriesAtAGlanceGraph from '../IncidentCategoriesAtAGlanceGraph';
import { firstDayOfLastJuly } from 'utils/firstDayOfLastJuly';
import { formatDate } from 'utils/formatDate';

export interface IDashboardIncidentsMainBranchContent {
	branch?: { id: string; name: string };
	renderingPdf: boolean;
}

const DashboardIncidentsMainBranchContent: React.FC<
	IDashboardIncidentsMainBranchContent
> = ({ branch = { id: '', name: '' }, renderingPdf }) => {
	const fromDate = firstDayOfLastJuly();
	const filters: IIncidentCategoryBreakdownFilters = {
		dateFrom: formatDate(fromDate),
	};

	if (branch.id) {
		filters.branchId = parseInt(branch.id, 10);
	}

	const { data } = useGetDashboardBreakdownQuery(filters);

	const categories: IIncidentCategorySummary[] = data?.categories || [];
	const graphCategories = data?.graphCategories || [];
	const renderClasses = renderingPdf ? '' : 'max-h-[600px] overflow-y-auto';

	return (
		<div className="flex space-x-[30px]">
			<div className="flex flex-col basis-2/5 min-w-[472px] items-stretch">
				<DashboardCard
					className={`basis-1/3 ${renderClasses} grow`}
					title="Incident Categories"
					total={data?.total}
					dividerColor="border-grey-200"
				>
					<DashboardIncidentCategoryBreakdownCard
						categories={categories}
						incidentPath="incidents"
						getExtraFilters={() => {
							return branch?.id ? { branchId: branch.id } : { branchId: 0 };
						}}
					/>
				</DashboardCard>
			</div>
			<div className="flex flex-col basis-3/5 min-w-[472px] items-stretch">
				<DashboardCard
					className="basis-2/3 max-h-[600px] min-h-[600px] overflow-y-auto"
					title="Incidents at a Glance"
					dividerColor="border-grey-200"
				>
					<IncidentCategoriesAtAGlanceGraph categoryData={graphCategories} />
				</DashboardCard>
			</div>
		</div>
	);
};

export default DashboardIncidentsMainBranchContent;
