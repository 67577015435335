import React from 'react';
import { IconPropTypes } from './ErrorSolid';

const SideBarIcon: React.FC<IconPropTypes> = ({
	color = '#FAA431',
	width = '16px',
	height = '16px',
	fill = 'none',
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill={fill}
		>
			<path
				d="M4.92373 3.38527C5.20695 3.38527 5.43655 3.61486 5.43655 3.89809C5.43655 4.18131 5.20695 4.41091 4.92373 4.41091H3.89809C3.61486 4.41091 3.38527 4.18131 3.38527 3.89809C3.38527 3.61486 3.61486 3.38527 3.89809 3.38527H4.92373Z"
				fill={fill === 'none' ? color : fill}
			/>
			<path
				d="M4.92373 5.43655C5.20695 5.43655 5.43655 5.66615 5.43655 5.94937C5.43655 6.23259 5.20695 6.46219 4.92373 6.46219H3.89809C3.61486 6.46219 3.38527 6.23259 3.38527 5.94937C3.38527 5.66615 3.61486 5.43655 3.89809 5.43655H4.92373Z"
				fill={fill === 'none' ? color : fill}
			/>
			<path
				d="M5.43655 8.00065C5.43655 7.71743 5.20695 7.48783 4.92373 7.48783H3.89809C3.61486 7.48783 3.38527 7.71743 3.38527 8.00065C3.38527 8.28387 3.61486 8.51347 3.89809 8.51347H4.92373C5.20695 8.51347 5.43655 8.28387 5.43655 8.00065Z"
				fill={fill === 'none' ? color : fill}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.33398 2.87245C1.33398 2.02278 2.02278 1.33398 2.87245 1.33398H13.1289C13.9785 1.33398 14.6673 2.02278 14.6673 2.87245V13.1289C14.6673 13.9785 13.9785 14.6673 13.1289 14.6673H2.87245C2.02278 14.6673 1.33398 13.9785 1.33398 13.1289V2.87245ZM7.48783 2.35963H13.1289C13.4121 2.35963 13.6417 2.58922 13.6417 2.87245V13.1289C13.6417 13.4121 13.4121 13.6417 13.1289 13.6417H7.48783V2.35963ZM6.46219 2.35963H2.87245C2.58922 2.35963 2.35963 2.58922 2.35963 2.87245V13.1289C2.35963 13.4121 2.58922 13.6417 2.87245 13.6417H6.46219V2.35963Z"
				fill={fill === 'none' ? color : fill}
			/>
		</svg>
	);
};

export default SideBarIcon;
