import { IIncidentCategorySummary } from 'types/VehicleIncidents';
import { useGetDashboardBreakdownQuery } from 'redux/api/vehicleIncidents';
import DashboardIncidentCategoryBreakdownCard from '../DashboardIncidentCategoryBreakdownCard';
import DashboardCard from '../DashboardCard';
import Heading from 'components/atoms/Heading';
import IncidentCategoriesAtAGlanceGraph from '../IncidentCategoriesAtAGlanceGraph';

export interface IDashboardIncidentsMainAll {
	pdfRef: React.RefObject<HTMLDivElement>;
	renderingPdf: boolean;
}

const DashboardIncidentsMainAll: React.FC<IDashboardIncidentsMainAll> = ({
	pdfRef,
	renderingPdf,
}) => {
	const { data } = useGetDashboardBreakdownQuery({});
	const categories: IIncidentCategorySummary[] = data?.categories || [];
	const graphCategories = data?.graphCategories || [];
	const renderClasses = renderingPdf ? '' : 'max-h-[600px] overflow-y-auto';

	return (
		<div className={`pt-6 ${renderingPdf ? '' : 'max-h-[700px]'}`} ref={pdfRef}>
			<Heading type="h3" className="mb-4 text-[24px]">
				All Branches
			</Heading>
			<div className="flex space-x-[30px]">
				<div className="flex flex-col basis-2/5 min-w-[472px] items-stretch">
					<DashboardCard
						className={`basis-1/3 ${renderClasses} grow`}
						title="Incident Categories"
						total={data?.total}
						dividerColor="border-grey-200"
					>
						<DashboardIncidentCategoryBreakdownCard
							categories={categories}
							incidentPath="incidents"
							getExtraFilters={() => ({})}
						/>
					</DashboardCard>
				</div>
				<div className="flex flex-col basis-3/5 min-w-[472px] items-stretch">
					<DashboardCard
						className="basis-2/3 max-h-[600px] min-h-[600px] overflow-y-auto"
						title="Incidents at a Glance"
						dividerColor="border-grey-200"
					>
						<IncidentCategoriesAtAGlanceGraph categoryData={graphCategories} />
					</DashboardCard>
				</div>
			</div>
		</div>
	);
};

export default DashboardIncidentsMainAll;
