import { useCallback, useEffect, useState } from 'react';
import { IOption } from 'types/VehicleFaults';
import CheckBoxV2 from '../../atoms/CheckBoxV2';

export interface IInPageSearchCheckBoxGroupStandalone {
	allCheckBox?: boolean;
	tidyTitles?: boolean;
	filters: IOption[];
	setFilters: (filters: IOption[]) => void;
}
const InPageSearchCheckBoxGroupStandalone: React.FC<
	IInPageSearchCheckBoxGroupStandalone
> = ({ allCheckBox, tidyTitles = false, filters, setFilters }) => {
	const updateFilterStateByType = (filterState: IOption[]) => {
		setFilters(filterState);
	};
	const getFilterState = useCallback(() => {
		return JSON.parse(JSON.stringify(filters));
	}, [filters]);

	const onChangeV2 = (id: number, checked: boolean) => {
		const filterState = JSON.parse(JSON.stringify(filters));
		const index = filterState.findIndex(
			(item: IOption) => item.id === Number(id)
		);

		filterState[index].checked = !checked;
		setIsAllChecked(false);

		updateFilterStateByType(filterState);
	};

	const [isAllChecked, setIsAllChecked] = useState<boolean>(true);
	const onChangeAll = (id: number, checked: boolean) => {
		const filterState = getFilterState();

		for (const checkBoxItem of filterState) {
			checkBoxItem.checked = !checked;
		}
		setIsAllChecked(!checked);

		updateFilterStateByType(filterState);
	};

	useEffect(() => {
		const filterState = getFilterState();
		const checkBoxItems = filterState;
		if (!checkBoxItems || checkBoxItems?.length < 1) {
			setIsAllChecked(false);
		} else {
			const isAllChecked = checkBoxItems.every((item: IOption) => item.checked);
			setIsAllChecked(isAllChecked);
		}
	}, [getFilterState]);

	return (
		<div className="grid gap-2 grid-col grid-cols-2 space-y-2 grid-flow-row justify-items-start">
			{allCheckBox && (
				<CheckBoxV2
					item={{
						checked: isAllChecked,
						id: 0,
						title: 'All',
						description: '',
					}}
					onChange={onChangeAll}
				/>
			)}
			<>
				{(filters as IOption[])?.map((item: IOption) => (
					<CheckBoxV2
						key={item.id}
						item={item}
						onChange={onChangeV2}
						tidyTitle={tidyTitles}
					/>
				))}
			</>
		</div>
	);
};

export default InPageSearchCheckBoxGroupStandalone;
