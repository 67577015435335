import Heading from 'components/atoms/Heading';
import Button from 'components/atoms/Button';
import SideBarIcon from 'assets/icons/SideBarIcon';
import ExportIcon from 'assets/icons/ExportIcon';
import DashboardIncidentsQuicklinks, {
	QuicklinkOptions,
} from '../DashboardIncidentsQuicklinks/DashboardIncidentsQuicklinks';

export interface IDashboardIncidentsMainHeader {
	onExportPdf: () => void;
	onOpenFilters: () => void;
	onChangeQuicklink: (quicklink: QuicklinkOptions) => void;
	activeQuicklink: QuicklinkOptions;
	filtersHaveChanged: boolean;
	filtersOpen: boolean;
}

const DashboardIncidentsMainHeader: React.FC<IDashboardIncidentsMainHeader> = ({
	onExportPdf,
	onOpenFilters,
	onChangeQuicklink,
	activeQuicklink,
	filtersHaveChanged,
	filtersOpen,
}) => {
	return (
		<div>
			<Heading type="h1" className="mb-10">
				INCIDENTS REPORTING
			</Heading>
			<div className="flex flex-row justify-between -mx-12 px-12 border-b border-scorpion-200">
				{!filtersOpen && (
					<div className="pt-1">
						<Button
							type="secondary"
							onClick={onOpenFilters}
							className={`text-[14px] tracking-[1.92px] !rounded-3xl !bg-transparent px-10`}
						>
							FILTER REPORT
							<span className="ml-[8px]">
								<SideBarIcon fill="currentColor" />
							</span>
						</Button>
					</div>
				)}
				<DashboardIncidentsQuicklinks
					onChangeQuicklink={onChangeQuicklink}
					activeQuicklink={activeQuicklink}
					filtersHaveChanged={filtersHaveChanged}
				/>
				<div className="pt-1">
					<Button
						onClick={onExportPdf}
						className={`text-[14px] tracking-[1.92px] !rounded-3xl px-6`}
					>
						<span className="mr-[8px]">
							<ExportIcon fill="currentColor" />
						</span>
						EXPORT PDF
					</Button>
				</div>
			</div>
		</div>
	);
};

export default DashboardIncidentsMainHeader;
