import React from 'react';

export interface IconPropTypes {
	color?: string;
	width?: string;
	height?: string;
	fill?: string;
}

const ErrorSolid: React.FC<IconPropTypes> = ({
	color,
	width = '20',
	height = '20',
}) => {
	return (
		<svg
			width={width}
			height={height}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			fill={color ? color : 'currentColour'}
			className="size-6"
		>
			<path
				fillRule="evenodd"
				d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
				clipRule="evenodd"
			/>
		</svg>
	);
};

export default ErrorSolid;
