import {
	IIncidentCriticalActionsSummary,
	IIncidentCategoryBreakdownFilters,
} from 'types/VehicleIncidents';
import { useGetDashboardBreakdownByCriticalActionsQuery } from 'redux/api/vehicleIncidents';
import DashboardIncidentCriticalActionsBreakdownCard from '../DashboardIncidentCriticalActionsBreakdownCard';
import DashboardCard from '../DashboardCard';
import Heading from 'components/atoms/Heading';
import IncidentCriticalActionsAtAGlanceGraph from '../IncidentCriticalActionsAtAGlanceGraph';
import { firstDayOfLastJuly } from 'utils/firstDayOfLastJuly';
import { formatDate } from 'utils/formatDate';

export interface IDashboardIncidentsMainCriticalActions {
	pdfRef: React.RefObject<HTMLDivElement>;
	renderingPdf: boolean;
}

const DashboardIncidentsMainCriticalActions: React.FC<
	IDashboardIncidentsMainCriticalActions
> = ({ pdfRef, renderingPdf }) => {
	const fromDate = firstDayOfLastJuly();
	const filters: IIncidentCategoryBreakdownFilters = {
		dateFrom: formatDate(fromDate),
	};
	const { data } = useGetDashboardBreakdownByCriticalActionsQuery(filters);
	const criticalActions: IIncidentCriticalActionsSummary[] =
		data?.criticalActions || [];
	const graphCriticalActions = data?.graphCriticalActions || [];
	const renderClasses = renderingPdf ? '' : 'max-h-[600px] overflow-y-auto';

	return (
		<div className={`pt-6 ${renderingPdf ? '' : 'max-h-[700px]'}`} ref={pdfRef}>
			<Heading type="h3" className="mb-4 text-[24px]">
				All Critical Actions
			</Heading>
			<div className="flex space-x-[30px]">
				<div className="flex flex-col basis-2/5 min-w-[472px] items-stretch">
					<DashboardCard
						className={`basis-1/3 ${renderClasses} grow`}
						title="Critical Actions"
						total={data?.total}
						dividerColor="border-grey-200"
					>
						<DashboardIncidentCriticalActionsBreakdownCard
							criticalActions={criticalActions}
							incidentPath="incidents"
						/>
					</DashboardCard>
				</div>
				<div className="flex flex-col basis-3/5 min-w-[472px] items-stretch">
					<DashboardCard
						className="basis-2/3 max-h-[600px] min-h-[600px] overflow-y-auto"
						title="Critical Actions at a Glance"
						dividerColor="border-grey-200"
					>
						<IncidentCriticalActionsAtAGlanceGraph
							graphCriticalActions={graphCriticalActions}
						/>
					</DashboardCard>
				</div>
			</div>
		</div>
	);
};

export default DashboardIncidentsMainCriticalActions;
