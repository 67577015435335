import React from 'react';
import DashboardStatusButton from 'components/atoms/DashboardStatusButton';
import { Link } from 'react-router-dom';
import { useBranchesFromPermsQuery } from 'redux/api/branchAPI';
import { IIncidentCategorySummary } from 'types/VehicleIncidents';
import { createCategoryAcronym } from 'utils/createCategoryAcronym';

export interface IDashboardIncidentCategoryBreakdownCard {
	className?: string;
	categories: IIncidentCategorySummary[] | undefined;
	incidentPath?: string;
	getExtraFilters?: () => { [key: string]: string | number };
}

const DashboardIncidentCategoryBreakdownCard: React.FC<
	IDashboardIncidentCategoryBreakdownCard
> = ({
	className = '',
	categories,
	incidentPath,
	getExtraFilters = () => ({}),
}) => {
	var incident_total: JSX.Element | string = '';
	const extraFilters: { [key: string]: string | number } = {
		...getExtraFilters(),
	};
	const { data: branchData } = useBranchesFromPermsQuery();

	if (extraFilters?.branchId === 0) {
		delete extraFilters.branchId;
	} else if (extraFilters?.branchId) {
		const currentBranch = branchData?.find((branch) => {
			return branch.id === extraFilters?.branchId;
		});

		delete extraFilters.branchId;

		if (currentBranch) {
			//@ts-ignore
			extraFilters.branch_name = currentBranch.label;
		}
	}

	//@ts-ignore
	const urlString = new URLSearchParams(extraFilters).toString();

	return (
		<table className={`${className} px-8 w-full`}>
			<thead className="border-b border-gray-200">
				<tr>
					<th className="text-secondary font-normal text-left pr-8 pb-3">
						Category
					</th>
					<th className="text-secondary font-normal text-left pr-8 pb-3">
						Total
					</th>
					<th className="text-secondary font-normal text-left pr-8 pb-3">
						Status
					</th>
					<th className="text-secondary font-normal text-left pr-8 pb-3">
						Severity
					</th>
					<th className="text-secondary font-normal text-left pr-8 pb-3">
						Critical Action
					</th>
				</tr>
			</thead>
			<tbody>
				{categories?.map((category: IIncidentCategorySummary) => {
					incident_total = (
						<Link
							to={`/${incidentPath}?category=${category.name}&${urlString}`}
						>
							{category.statistics.total}
						</Link>
					);

					const catName = createCategoryAcronym(category.name);

					return (
						<tr
							className="border-b border-gray-200 py-4 font-normal font-scorpion-200"
							key={category.id}
						>
							<td className="pr-8 py-5 font-inter text-scorpion-500">
								{catName}
							</td>
							<td className="pr-8 py-5 underline font-inter text-scorpion-500">
								{incident_total}
							</td>
							<td>
								<div className="pr-8 flex flex-row flex-wrap gap-y-2 gap-x-2 py-3">
									{['new', 'draft', 'submitted', 'open', 'closed'].map(
										(status) => {
											// @ts-ignore trust me bro
											if (category.statistics[status] === 0) {
												return null;
											}
											const extraLinkQuery = `category=${category.name}&status=${status}&${urlString}`;
											return (
												<DashboardStatusButton
													key={`status-${category.id}-${status}`}
													status={status}
													modulePath={incidentPath}
													// @ts-ignore trust me bro x2
													total={category.statistics[status]}
													extraLinkQuery={extraLinkQuery}
													totalBrackets={true}
												/>
											);
										}
									)}
								</div>
							</td>
							<td>
								<div className="pr-8 flex flex-row flex-wrap gap-y-2 gap-x-2 py-3">
									{['Level 1', 'Level 2', 'Level 3', 'Level 4', 'Level 5'].map(
										(severity) => {
											// @ts-ignore trust me bro
											if (category.statistics.severities[severity] === 0) {
												return null;
											}
											const extraLinkQuery = `category=${category.name}&severity=${severity}&${urlString}`;
											// get number from end of severity string
											const severityNumber = severity.match(/\d+/);
											return (
												<DashboardStatusButton
													key={`severity-${category.id}-${severity}`}
													status={severityNumber?.toString() || ''}
													modulePath={incidentPath}
													// @ts-ignore trust me bro x2
													total={category.statistics.severities[severity]}
													extraLinkQuery={extraLinkQuery}
													totalBrackets={true}
												/>
											);
										}
									)}
								</div>
							</td>
							<td>
								<div className="pr-8 flex flex-row flex-wrap gap-y-2 gap-x-2 py-3">
									{category.statistics.actions['open'] > 0 && (
										<DashboardStatusButton
											status="Open"
											modulePath={incidentPath}
											total={category.statistics.actions['open']}
											extraLinkQuery={`category=${category.name}&correctiveActions=open&${urlString}`}
											totalBrackets={true}
										/>
									)}
									{category.statistics.actions['overdue'] > 0 && (
										<DashboardStatusButton
											status="Overdue"
											modulePath={incidentPath}
											total={category.statistics.actions['overdue']}
											extraLinkQuery={`category=${category.name}&correctiveActions=overdue&${urlString}`}
											totalBrackets={true}
										/>
									)}
									{category.statistics.actions['completed'] > 0 && (
										<DashboardStatusButton
											status="Complete"
											modulePath={incidentPath}
											total={category.statistics.actions['completed']}
											extraLinkQuery={`category=${category.name}&correctiveActions=completed&${urlString}`}
											totalBrackets={true}
										/>
									)}
								</div>
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default DashboardIncidentCategoryBreakdownCard;
