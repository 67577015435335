import React from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ChartOptions,
	TooltipItem,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { IIncident, IMonth, IMonthBranch } from 'types/Branches';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

export interface ITop5MonthlyIncidentsGraph {
	monthData: IMonth[];
}

const Top5MonthlyIncidentsGraph: React.FC<ITop5MonthlyIncidentsGraph> = ({
	monthData,
}) => {
	const options: ChartOptions<'bar'> = {
		plugins: {
			legend: {
				align: 'start',
				fullSize: true,
				labels: {
					usePointStyle: true,
					pointStyle: 'rect',
					boxHeight: 10,
					font: {
						size: 12,
					},
				},
				position: 'bottom',
			},
			tooltip: {
				displayColors: true,
				callbacks: {
					//removes default tooltip body information
					label() {
						return '';
					},
					afterBody(context: TooltipItem<'bar'>[]) {
						const currentMonth = monthData.find(
							(currentMonthData: IMonth) =>
								currentMonthData.month === context[0].label
						);

						if (currentMonth) {
							const resultString = [];

							if (currentMonth.incidents > 0) {
								resultString.push(`Incidents: ${currentMonth.incidents}`);
							}

							//format and sort incidents to push to string
							const incidents = currentMonth.branches
								.map((branch: IMonthBranch) => {
									return {
										name: branch.name,
										incidents: branch.incidents,
									};
								})
								.filter((branch: IIncident) => branch.incidents !== 0)
								.sort(
									(a: IIncident, b: IIncident) => b.incidents - a.incidents
								);

							incidents.forEach((incident: IIncident) =>
								resultString.push(` - ${incident.name}: ${incident.incidents}`)
							);

							const total = currentMonth.incidents;

							if (total > 0) {
								resultString.push('');
								resultString.push(`Total: ${total}`);
							}

							return resultString;
						} else {
							return [`${context[0].dataset.label}: ${context[0].raw}`];
						}
					},
				},
				padding: {
					left: 10,
					right: 30,
					y: 10,
				},
				borderColor: 'rgba(52, 64, 84, 0.5)',
				borderWidth: 1,
				backgroundColor: '#FFFFFF',
				titleColor: '#344054',
				bodyColor: '#344054',
				bodyFont: {
					size: 11,
				},
			},
		},
		responsive: true,
		interaction: {
			mode: 'index' as const,
			intersect: false,
		},
		scales: {
			x: {
				stacked: true,
			},
			y: {
				stacked: true,
			},
		},
		// @ts-ignore - ChartJS types are simply incorrect
		barThickness: 24,
		maintainAspectRatio: false,
	};

	const data = {
		labels: monthData.map((branch: IMonth) => branch.month),
		datasets: [
			{
				label: 'Incidents',
				data: monthData.map((branch: IMonth) => branch.incidents),
				backgroundColor: '#FAA431',
				stack: 'Stack 0',
			},
		],
	};

	return <Bar options={options} data={data} />;
};

export default Top5MonthlyIncidentsGraph;
