import TabLink from 'components/atoms/TabLink';

export interface IDashboardIncidentsQuicklinks {
	onChangeQuicklink: (quicklink: QuicklinkOptions) => void;
	activeQuicklink: QuicklinkOptions;
	filtersHaveChanged: boolean;
}

export type QuicklinkOptions =
	| 'all'
	| 'branches'
	| 'severity'
	| 'open'
	| 'mechanism'
	| 'agency'
	| 'critical'
	| 'filtered';

export const quickLinkTabs: { slug: QuicklinkOptions; label: string }[] = [
	{
		slug: 'all',
		label: 'All',
	},
	{
		slug: 'branches',
		label: 'Branches',
	},
	{
		slug: 'severity',
		label: 'Severity',
	},
	{
		slug: 'open',
		label: 'Open Incidents',
	},
	{
		slug: 'mechanism',
		label: 'Mechanism',
	},
	{
		slug: 'agency',
		label: 'Agency',
	},
	{
		slug: 'critical',
		label: 'Critical Actions',
	},
	{
		slug: 'filtered',
		label: 'Filtered',
	},
];

const DashboardIncidentsQuicklinks: React.FC<IDashboardIncidentsQuicklinks> = ({
	onChangeQuicklink,
	activeQuicklink,
	filtersHaveChanged,
}) => {
	return (
		<div>
			<div className="flex items-center justify-center flex-1 space-x-4">
				{quickLinkTabs.map((tabLink) => {
					if (tabLink.slug === 'filtered' && !filtersHaveChanged) {
						return null;
					}

					return (
						<TabLink
							key={tabLink.slug}
							onClick={() => {
								onChangeQuicklink(tabLink.slug);
							}}
							active={activeQuicklink === tabLink.slug}
							v2={true}
							fontColor="text-scorpion-300"
							fontHoverColor="text-primary-500"
							fontBoldness="font-medium"
							fontSize="text-[22px]"
						>
							{tabLink.label}
						</TabLink>
					);
				})}
			</div>
		</div>
	);
};

export default DashboardIncidentsQuicklinks;
