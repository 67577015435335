export const firstDayOfLastJuly = (utc = false) => {
	const now = new Date();
	const currentYear = now.getUTCFullYear();
	const previousJulyYear =
		now.getUTCMonth() < 6 ? currentYear - 1 : currentYear;

	if (!utc) {
		const firstDayOfPreviousJuly = new Date(previousJulyYear, 6, 1);
		return firstDayOfPreviousJuly;
	} else {
		const firstDayOfPreviousJuly = new Date(Date.UTC(previousJulyYear, 6, 1));
		return firstDayOfPreviousJuly;
	}
};
