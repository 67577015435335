import React from 'react';
import DashboardStatusButton from 'components/atoms/DashboardStatusButton';
import { Link } from 'react-router-dom';
import { IIncidentOpenIncidentsSummary } from 'types/VehicleIncidents';

export interface IDashboardIncidentOpenIncidentsBreakdownCard {
	className?: string;
	openIncidents: IIncidentOpenIncidentsSummary[] | undefined;
	incidentPath?: string;
	getExtraFilters?: () => { [key: string]: string | number };
}

const DashboardIncidentOpenIncidentsBreakdownCard: React.FC<
	IDashboardIncidentOpenIncidentsBreakdownCard
> = ({
	className = '',
	openIncidents,
	incidentPath,
	getExtraFilters = () => ({}),
}) => {
	const extraFilters: { [key: string]: string | number } = {
		...getExtraFilters(),
	};

	//@ts-ignore
	const urlString = new URLSearchParams(extraFilters).toString();

	return (
		<table className={`${className} px-8 w-full`}>
			<thead className="border-b border-gray-200">
				<tr>
					<th className="text-secondary font-normal text-left pr-8 pb-3">
						Status
					</th>
					<th className="text-secondary font-normal text-left pr-8 pb-3">
						Total
					</th>
					<th className="text-secondary font-normal text-left pr-8 pb-3">
						Severity
					</th>
					<th className="text-secondary font-normal text-left pr-8 pb-3">
						Critical Action
					</th>
					<th className="text-secondary font-normal text-left pr-8 pb-3">
						Priority
					</th>
				</tr>
			</thead>
			<tbody>
				{openIncidents?.map((openIncident: IIncidentOpenIncidentsSummary) => {
					const incident_total = (
						<Link
							to={`/${incidentPath}?status=${openIncident.name}&${urlString}`}
						>
							{openIncident.statistics.total}
						</Link>
					);

					return (
						<tr
							className="border-b border-gray-200 py-4 font-normal font-scorpion-200"
							key={openIncident.id}
						>
							<td className="pr-8 py-5 font-inter text-scorpion-500">
								{openIncident.name}
							</td>
							<td className="pr-8 py-5 underline font-inter text-scorpion-500">
								{incident_total}
							</td>
							<td>
								<div className="pr-8 flex flex-row flex-wrap gap-y-2 gap-x-2 py-3">
									{['Level 1', 'Level 2', 'Level 3', 'Level 4', 'Level 5'].map(
										(severity) => {
											// @ts-ignore trust me bro
											if (openIncident.statistics.severities[severity] === 0) {
												return null;
											}
											const extraLinkQuery = `status=${openIncident.name}&severity=${severity}&${urlString}`;
											// get number from end of severity string
											const severityNumber = severity.match(/\d+/);
											return (
												<DashboardStatusButton
													key={`severity-${openIncident.id}-${severity}`}
													status={severityNumber?.toString() || ''}
													modulePath={incidentPath}
													// @ts-ignore trust me bro x2
													total={openIncident.statistics.severities[severity]}
													extraLinkQuery={extraLinkQuery}
													totalBrackets={true}
												/>
											);
										}
									)}
								</div>
							</td>
							<td>
								<div className="pr-8 flex flex-row flex-wrap gap-y-2 gap-x-2 py-3">
									{openIncident.statistics.actions['open'] > 0 && (
										<DashboardStatusButton
											status="Open"
											modulePath={incidentPath}
											total={openIncident.statistics.actions['open']}
											extraLinkQuery={`status=${openIncident.name}&correctiveActions=open&${urlString}`}
											totalBrackets={true}
										/>
									)}
									{openIncident.statistics.actions['overdue'] > 0 && (
										<DashboardStatusButton
											status="Overdue"
											modulePath={incidentPath}
											total={openIncident.statistics.actions['overdue']}
											extraLinkQuery={`status=${openIncident.name}&correctiveActions=overdue&${urlString}`}
											totalBrackets={true}
										/>
									)}
									{openIncident.statistics.actions['completed'] > 0 && (
										<DashboardStatusButton
											status="Complete"
											modulePath={incidentPath}
											total={openIncident.statistics.actions['completed']}
											extraLinkQuery={`status=${openIncident.name}&correctiveActions=completed&${urlString}`}
											totalBrackets={true}
										/>
									)}
								</div>
							</td>
							<td>
								<div className="pr-8 flex flex-row flex-wrap gap-y-2 gap-x-2 py-3">
									{['Low', 'Medium', 'High', 'Critical'].map((priority) => {
										// @ts-ignore trust me bro
										if (openIncident.statistics.priorities[priority] === 0) {
											return null;
										}
										const extraLinkQuery = `status=${openIncident.name}&correctiveActionsPriority=${priority}&${urlString}`;
										return (
											<DashboardStatusButton
												key={`priority-${openIncident.id}-${priority}`}
												status={priority}
												modulePath={incidentPath}
												// @ts-ignore trust me bro x2
												total={openIncident.statistics.priorities[priority]}
												extraLinkQuery={extraLinkQuery}
												totalBrackets={true}
											/>
										);
									})}
								</div>
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default DashboardIncidentOpenIncidentsBreakdownCard;
