import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { ChartOptions } from 'chart.js/dist/types/index';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { IStatesTotalByType } from 'types/VehicleIncidents';

ChartJS.register(ArcElement, Tooltip, Legend);

export interface IStateTotalDonutChart {
	className?: string;
	states: IStatesTotalByType[];
}

const StateTotalDonutChart: React.FC<IStateTotalDonutChart> = ({
	className,
	states,
}) => {
	const filteredStates = states.filter((state) => state.total);

	const data = {
		labels: filteredStates.map((state) => state.name),
		datasets: [
			{
				label: '# of Incidents',
				data: filteredStates.map((state) => state.total),
				backgroundColor: [
					'#5B8FF9',
					'#5AD8A6',
					'#5D7092',
					'#F6BD16',
					'#E8684A',
					'#6DC8EC',
					'#000000',
				],
				borderColor: ['white'],
				borderWidth: 2,
				cutout: '65%',
			},
		],
	};

	const options: ChartOptions<'doughnut'> = {
		plugins: {
			legend: {
				position: 'bottom',
				align: 'start',
				fullSize: true,
				labels: {
					usePointStyle: true,
					pointStyle: 'circle',
					boxHeight: 8,
					font: {
						size: 16,
					},
					padding: 10,
				},
			},
			tooltip: {
				enabled: false,
			},
			datalabels: {
				color: 'blue',
				labels: {
					value: {
						color: [
							'black',
							'black',
							'white',
							'black',
							'black',
							'black',
							'white',
						],
						display: filteredStates.map((state) => state.total > 0),
						font: {
							size: 16,
						},
					},
				},
			},
		},
	};

	return (
		<div
			className={`flex justify-center items-center grow flex-col ${
				className ? className : ''
			}`}
		>
			{/*@ts-ignore*/}
			<Doughnut plugins={[ChartDataLabels]} data={data} options={options} />
		</div>
	);
};

export default StateTotalDonutChart;
