import DashboardIncidentsMainAll from '../DashboardIncidentsMainAll';
import DashboardIncidentsMainAgency from '../DashboardIncidentsMainAgency';
import DashboardIncidentsMainBranches from '../DashboardIncidentsMainBranches';
import DashboardIncidentsMainMechanism from '../DashboardIncidentsMainMechanism';
import DashboardIncidentsMainSeverity from '../DashboardIncidentsMainSeverity';
import DashboardIncidentsMainFiltered from '../DashboardIncidentsMainFiltered';
import DashboardIncidentsMainOpenIncidents from '../DashboardIncidentsMainOpenIncidents';
import DashboardIncidentsMainCriticalActions from '../DashboardIncidentsMainCriticalActions';
import DashboardIncidentsMainHeader from '../DashboardIncidentsMainHeader/DashboardIncidentsMainHeader';
import { useEffect, useRef, useState } from 'react';
import { QuicklinkOptions } from '../DashboardIncidentsQuicklinks/DashboardIncidentsQuicklinks';
import { IFiltersDashboardIncidents } from 'types/VehicleIncidents';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';

export interface IDashboardIncidentsMain {
	onOpenFilters: () => void;
	filters: IFiltersDashboardIncidents;
	filtersHaveChanged: boolean;
	activeQuicklink: QuicklinkOptions;
	setActiveQuickLink: (quicklink: QuicklinkOptions) => void;
	filtersOpen: boolean;
}

export interface ISectionPdfRefs {
	[QuicklinkOptions: string]: React.RefObject<HTMLDivElement>;
}

const DashboardIncidentsMain: React.FC<IDashboardIncidentsMain> = ({
	onOpenFilters,
	filters,
	filtersHaveChanged,
	activeQuicklink,
	setActiveQuickLink,
	filtersOpen,
}) => {
	useEffect(() => {
		if (filtersHaveChanged) {
			setActiveQuickLink('filtered');
		}
	}, [filtersHaveChanged, setActiveQuickLink]);

	const sectionPdfRefs: ISectionPdfRefs = {
		all: useRef(null),
		agency: useRef(null),
		branches: useRef(null),
		mechanism: useRef(null),
		severity: useRef(null),
		filtered: useRef(null),
		open: useRef(null),
		critical: useRef(null),
	};

	const currentSimpleDateString = new Date().toLocaleDateString('en-US', {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	});

	const [renderingPdf, setRenderingPdf] = useState(false);

	useEffect(() => {
		if (renderingPdf) {
			setTimeout(() => {
				generatePDF(sectionPdfRefs[activeQuicklink], {
					filename: `dashboard-incidents-${activeQuicklink}-${currentSimpleDateString}.pdf`,
					page: {
						format: 'letter',
						orientation: 'portrait',
						margin: Margin.MEDIUM,
					},
					resolution: Resolution.MEDIUM,
				});
			}, 1000);
		}
		// eslint-disable-next-line
	}, [renderingPdf, activeQuicklink, currentSimpleDateString]);

	return (
		<div>
			<DashboardIncidentsMainHeader
				onExportPdf={() => {
					setRenderingPdf(true);
					setTimeout(() => {
						setRenderingPdf(false);
					}, 3000);
				}}
				onOpenFilters={onOpenFilters}
				onChangeQuicklink={setActiveQuickLink}
				activeQuicklink={activeQuicklink}
				filtersHaveChanged={filtersHaveChanged}
				filtersOpen={filtersOpen}
			/>
			{activeQuicklink === 'all' && (
				<DashboardIncidentsMainAll
					pdfRef={sectionPdfRefs['all']}
					renderingPdf={renderingPdf}
				/>
			)}
			{activeQuicklink === 'agency' && (
				<DashboardIncidentsMainAgency
					pdfRef={sectionPdfRefs['agency']}
					renderingPdf={renderingPdf}
				/>
			)}
			{activeQuicklink === 'branches' && (
				<DashboardIncidentsMainBranches
					pdfRef={sectionPdfRefs['branches']}
					renderingPdf={renderingPdf}
				/>
			)}
			{activeQuicklink === 'mechanism' && (
				<DashboardIncidentsMainMechanism
					pdfRef={sectionPdfRefs['mechanism']}
					renderingPdf={renderingPdf}
				/>
			)}
			{activeQuicklink === 'severity' && (
				<DashboardIncidentsMainSeverity
					pdfRef={sectionPdfRefs['severity']}
					renderingPdf={renderingPdf}
				/>
			)}
			{activeQuicklink === 'filtered' && (
				<DashboardIncidentsMainFiltered
					pdfRef={sectionPdfRefs['filtered']}
					filters={filters}
					renderingPdf={renderingPdf}
				/>
			)}
			{activeQuicklink === 'open' && (
				<DashboardIncidentsMainOpenIncidents
					pdfRef={sectionPdfRefs['open']}
					renderingPdf={renderingPdf}
				/>
			)}
			{activeQuicklink === 'critical' && (
				<DashboardIncidentsMainCriticalActions
					pdfRef={sectionPdfRefs['critical']}
					renderingPdf={renderingPdf}
				/>
			)}
		</div>
	);
};

export default DashboardIncidentsMain;
