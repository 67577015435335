import React from 'react';
import DashboardStatusButton from 'components/atoms/DashboardStatusButton';
import { Link } from 'react-router-dom';
import { IIncidentCriticalActionsSummary } from 'types/VehicleIncidents';

export interface IDashboardIncidentCriticalActionsBreakdownCard {
	className?: string;
	criticalActions: IIncidentCriticalActionsSummary[] | undefined;
	incidentPath?: string;
	getExtraFilters?: () => { [key: string]: string | number };
}

const DashboardIncidentCriticalActionsBreakdownCard: React.FC<
	IDashboardIncidentCriticalActionsBreakdownCard
> = ({
	className = '',
	criticalActions,
	incidentPath,
	getExtraFilters = () => ({}),
}) => {
	const extraFilters: { [key: string]: string | number } = {
		...getExtraFilters(),
	};

	//@ts-ignore
	const urlString = new URLSearchParams(extraFilters).toString();

	return (
		<table className={`${className} px-8 w-full`}>
			<thead className="border-b border-gray-200">
				<tr>
					<th className="text-secondary font-normal text-left pr-8 pb-3">
						Priority
					</th>
					<th className="text-secondary font-normal text-left pr-8 pb-3">
						Total
					</th>
					<th className="text-secondary font-normal text-left pr-8 pb-3">
						Status
					</th>
					<th className="text-secondary font-normal text-left pr-8 pb-3">
						Severity
					</th>
					<th className="text-secondary font-normal text-left pr-8 pb-3">
						Critical Action
					</th>
				</tr>
			</thead>
			<tbody>
				{criticalActions?.map(
					(criticalAction: IIncidentCriticalActionsSummary) => {
						const incident_total = (
							<Link
								to={`/${incidentPath}?correctiveActionsPriority=${criticalAction.name}&${urlString}`}
							>
								{criticalAction.statistics.total}
							</Link>
						);

						return (
							<tr
								className="border-b border-gray-200 py-4 font-normal font-scorpion-200"
								key={criticalAction.id}
							>
								<td className="pr-8 py-5 font-inter text-scorpion-500">
									{criticalAction.name}
								</td>
								<td className="pr-8 py-5 underline font-inter text-scorpion-500">
									{incident_total}
								</td>
								<td>
									<div className="pr-8 flex flex-row flex-wrap gap-y-2 gap-x-2 py-3">
										{['new', 'draft', 'submitted', 'open', 'closed'].map(
											(status) => {
												// @ts-ignore trust me bro
												if (criticalAction.statistics[status] === 0) {
													return null;
												}
												const extraLinkQuery = `correctiveActionsPriority=${criticalAction.name}&status=${status}&${urlString}`;
												return (
													<DashboardStatusButton
														key={`status-${criticalAction.id}-${status}`}
														status={status}
														modulePath={incidentPath}
														// @ts-ignore trust me bro x2
														total={criticalAction.statistics[status]}
														extraLinkQuery={extraLinkQuery}
														totalBrackets={true}
													/>
												);
											}
										)}
									</div>
								</td>
								<td>
									<div className="pr-8 flex flex-row flex-wrap gap-y-2 gap-x-2 py-3">
										{[
											'Level 1',
											'Level 2',
											'Level 3',
											'Level 4',
											'Level 5',
										].map((severity) => {
											if (
												// @ts-ignore trust me bro
												criticalAction.statistics.severities[severity] === 0
											) {
												return null;
											}
											const extraLinkQuery = `correctiveActionsPriority=${criticalAction.name}&severity=${severity}&${urlString}`;
											// get number from end of severity string
											const severityNumber = severity.match(/\d+/);
											return (
												<DashboardStatusButton
													key={`severity-${criticalAction.id}-${severity}`}
													status={severityNumber?.toString() || ''}
													modulePath={incidentPath}
													// @ts-ignore trust me bro x2
													total={criticalAction.statistics.severities[severity]}
													extraLinkQuery={extraLinkQuery}
													totalBrackets={true}
												/>
											);
										})}
									</div>
								</td>
								<td>
									<div className="pr-8 flex flex-row flex-wrap gap-y-2 gap-x-2 py-3">
										{criticalAction.statistics.actions['open'] > 0 && (
											<DashboardStatusButton
												status="Open"
												modulePath={incidentPath}
												total={criticalAction.statistics.actions['open']}
												extraLinkQuery={`correctiveActionsPriority=${criticalAction.name}&correctiveActions=open&${urlString}`}
												totalBrackets={true}
											/>
										)}
										{criticalAction.statistics.actions['overdue'] > 0 && (
											<DashboardStatusButton
												status="Overdue"
												modulePath={incidentPath}
												total={criticalAction.statistics.actions['overdue']}
												extraLinkQuery={`correctiveActionsPriority=${criticalAction.name}&correctiveActions=overdue&${urlString}`}
												totalBrackets={true}
											/>
										)}
										{criticalAction.statistics.actions['completed'] > 0 && (
											<DashboardStatusButton
												status="Complete"
												modulePath={incidentPath}
												total={criticalAction.statistics.actions['completed']}
												extraLinkQuery={`correctiveActionsPriority=${criticalAction.name}&correctiveActions=completed&${urlString}`}
												totalBrackets={true}
											/>
										)}
									</div>
								</td>
							</tr>
						);
					}
				)}
			</tbody>
		</table>
	);
};

export default DashboardIncidentCriticalActionsBreakdownCard;
