import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import Heading from 'components/atoms/Heading';
import DashboardIncidentsMainBranchContent from '../DashboardIncidentsMainBranchContent';

export interface IDashboardIncidentsMainBranch {
	branch?: { id: string; name: string };
	isOpenDefault?: boolean;
	renderingPdf: boolean;
}

const DashboardIncidentsMainBranch: React.FC<IDashboardIncidentsMainBranch> = ({
	branch = { id: '', name: '' },
	isOpenDefault = false,
	renderingPdf,
}) => {
	const [isOpen, setIsOpen] = useState(isOpenDefault);

	return (
		<div className={`pt-6 ${renderingPdf ? '' : 'max-h-[700px]'}`}>
			<div
				className="flex items-center cursor-pointer"
				onClick={() => setIsOpen(!isOpen)}
			>
				{isOpen ? (
					<ChevronUpIcon className="h-6 w-6 text-gray-400 mb-4 mr-2" />
				) : (
					<ChevronDownIcon className="h-6 w-6 text-gray-400 mb-4 mr-2 text-primary-500" />
				)}
				<Heading type="h3" className="mb-4 text-[24px]">
					{branch.name || 'All Branches'}
				</Heading>
			</div>
			{isOpen && (
				<DashboardIncidentsMainBranchContent
					branch={branch}
					renderingPdf={renderingPdf}
				/>
			)}
		</div>
	);
};

export default DashboardIncidentsMainBranch;
