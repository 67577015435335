import React from 'react';
import { IconPropTypes } from './ErrorSolid';

const ExportIcon: React.FC<IconPropTypes> = ({
	color = '#FAA431',
	width = '16px',
	height = '16px',
	fill = 'none',
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill={fill}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.33333 11.3333C3.33333 12.0697 3.93029 12.6667 4.66667 12.6667L11.3333 12.6667C12.0697 12.6667 12.6667 12.0697 12.6667 11.3333L12.6667 10.6667C12.6667 10.2985 12.9651 10 13.3333 10C13.7015 10 14 10.2985 14 10.6667L14 11.3333C14 12.8061 12.8061 14 11.3333 14L4.66667 14C3.19391 14 2 12.8061 2 11.3333L2 10.6667C2 10.2985 2.29848 10 2.66667 10C3.03486 10 3.33333 10.2985 3.33333 10.6667L3.33333 11.3333ZM4.86193 5.80474C4.60158 5.54439 4.60158 5.12228 4.86193 4.86193L7.52859 2.19526C7.65362 2.07024 7.82319 2 8 2C8.17681 2 8.34638 2.07024 8.4714 2.19526L11.1381 4.86193C11.3984 5.12228 11.3984 5.54439 11.1381 5.80474C10.8777 6.06509 10.4556 6.06509 10.1953 5.80474L8.66667 4.27614L8.66666 10.6667C8.66666 11.0349 8.36819 11.3333 8 11.3333C7.63181 11.3333 7.33333 11.0349 7.33333 10.6667L7.33333 4.27614L5.80474 5.80474C5.54439 6.06509 5.12228 6.06509 4.86193 5.80474Z"
				fill={fill === 'none' ? color : fill}
			/>
		</svg>
	);
};

export default ExportIcon;
