import {
	IIncidentSeveritySummary,
	IIncidentCategoryBreakdownFilters,
} from 'types/VehicleIncidents';
import { useGetDashboardBreakdownBySeverityQuery } from 'redux/api/vehicleIncidents';
import DashboardIncidentSeverityBreakdownCard from '../DashboardIncidentSeverityBreakdownCard';
import DashboardCard from '../DashboardCard';
import Heading from 'components/atoms/Heading';
import IncidentSeveritiesAtAGlanceGraph from '../IncidentSeveritiesAtAGlanceGraph';
import { firstDayOfLastJuly } from 'utils/firstDayOfLastJuly';
import { formatDate } from 'utils/formatDate';

export interface IDashboardIncidentsMainSeverity {
	pdfRef: React.RefObject<HTMLDivElement>;
	renderingPdf: boolean;
}

const DashboardIncidentsMainSeverity: React.FC<
	IDashboardIncidentsMainSeverity
> = ({ pdfRef, renderingPdf }) => {
	const fromDate = firstDayOfLastJuly();
	const filters: IIncidentCategoryBreakdownFilters = {
		dateFrom: formatDate(fromDate),
	};
	const { data } = useGetDashboardBreakdownBySeverityQuery(filters);
	const severities: IIncidentSeveritySummary[] = data?.severities || [];
	const graphSeverities = data?.graphSeverities || [];
	const renderClasses = renderingPdf ? '' : 'max-h-[600px] overflow-y-auto';

	return (
		<div className={`pt-6 ${renderingPdf ? '' : 'max-h-[700px]'}`} ref={pdfRef}>
			<Heading type="h3" className="mb-4 text-[24px]">
				All Severities
			</Heading>
			<div className="flex space-x-[30px]">
				<div className="flex flex-col basis-2/5 min-w-[472px] items-stretch">
					<DashboardCard
						className={`basis-1/3 ${renderClasses} grow`}
						title="Incident Severities"
						total={data?.total}
						dividerColor="border-grey-200"
					>
						<DashboardIncidentSeverityBreakdownCard
							severities={severities}
							incidentPath="incidents"
						/>
					</DashboardCard>
				</div>
				<div className="flex flex-col basis-3/5 min-w-[472px] items-stretch">
					<DashboardCard
						className="basis-2/3 max-h-[600px] min-h-[600px] overflow-y-auto"
						title="Severities at a Glance"
						dividerColor="border-grey-200"
					>
						<IncidentSeveritiesAtAGlanceGraph severityData={graphSeverities} />
					</DashboardCard>
				</div>
			</div>
		</div>
	);
};

export default DashboardIncidentsMainSeverity;
