import Button from 'components/atoms/Button';
import DateInput from 'components/atoms/DateInput';
import Divider from 'components/atoms/Divider';
import Accordion from 'components/molecules/Accordion';
import InPageSearchCheckBoxGroupStandalone from 'components/molecules/InPageSearchCheckBoxGroupStandalone';
import React, { useEffect, useState } from 'react';
import { IFiltersDashboardIncidents } from 'types/VehicleIncidents';
import Heading from 'components/atoms/Heading';
import { XIcon } from '@heroicons/react/outline';

export interface IIncidentSearchBar {
	filters: IFiltersDashboardIncidents;
	setFilters: (filters: IFiltersDashboardIncidents) => void;
	open: boolean;
	setOpen: (open: boolean) => void;
	resetFilters: () => void;
}

const DashboardIncidentsSearchBar: React.FC<IIncidentSearchBar> = ({
	filters,
	setFilters,
	open,
	setOpen,
	resetFilters,
}) => {
	const [internalFilters, setInternalFilters] = useState(filters);
	const [defaultDate] = useState(null);

	// this is janky but it's needed for now apparently. will revisit and clean up later.
	useEffect(() => {
		const stateData = filters.state;
		const branchData = filters.branch;

		const filterState = JSON.parse(JSON.stringify(filters));
		filterState['state'] = stateData;
		filterState['branch'] = branchData;
		setInternalFilters(filterState);
	}, [setInternalFilters, filters]);

	return (
		<>
			{open && (
				<div className="h-full w-[415px] min-w-[415px] max-w-[415px] py-10 shadow-xl relative border-r border-gray-300">
					<div className="mx-auto w-full px-6">
						<div className="flex items-start justify-between">
							<Heading
								type="h2"
								className="text-[26px] font-bold tracking-[2.08px] "
							>
								FILTER BY
							</Heading>
							<div className="ml-3 flex h-7 items-center my-auto">
								<button
									type="button"
									className="rounded-md text-orange-500 hover:text-orange-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mx-auto"
									onClick={() => setOpen(false)}
								>
									<span className="sr-only">Close panel</span>
									<XIcon className="h-6 w-6" aria-hidden="true" />
								</button>
							</div>
						</div>
					</div>
					<div className="flex flex-col flex-1 px-4 sm:px-6 space-y-4 h-full pt-6 justify-items-start">
						<div className="flex flex-col grow overflow-y-scroll border-orange-500 border-t-[1px]">
							<div className="flex flex-col h-full">
								<Accordion title="Date Range" width="w-full" isOpen={true}>
									<div className="w-full grid gap-2 grid-col grid-cols-2">
										<DateInput
											placeholder="From"
											onChange={(value: Date) =>
												setInternalFilters({
													...internalFilters,
													from: new Date(
														Date.UTC(
															value.getFullYear(),
															value.getMonth(),
															value.getDate()
														)
													).toISOString(),
												})
											}
											inputLabel="From"
											iconClassName="text-secondary"
											selected={defaultDate}
										/>
										<DateInput
											placeholder="To"
											onChange={(value: Date) =>
												setInternalFilters({
													...internalFilters,
													to: new Date(
														Date.UTC(
															value.getFullYear(),
															value.getMonth(),
															value.getDate()
														)
													).toISOString(),
												})
											}
											inputLabel="To"
											iconClassName="text-secondary"
											selected={defaultDate}
										/>
									</div>
								</Accordion>
								<Divider color="border-[#DDDDDD] !border-t-[2px]" />
								<Accordion title="State" width="w-full">
									<InPageSearchCheckBoxGroupStandalone
										allCheckBox
										tidyTitles={true}
										filters={internalFilters['state']}
										setFilters={(newFilters) => {
											setInternalFilters({
												...internalFilters,
												state: newFilters,
											});
										}}
									/>
								</Accordion>
								<Divider color="border-[#DDDDDD] !border-t-[2px]" />
								<Accordion title="Branch" width="w-full">
									<InPageSearchCheckBoxGroupStandalone
										allCheckBox
										tidyTitles={true}
										filters={internalFilters['branch']}
										setFilters={(newFilters) => {
											setInternalFilters({
												...internalFilters,
												branch: newFilters,
											});
										}}
									/>
								</Accordion>
								<Divider color="border-[#DDDDDD] !border-t-[2px]" />
								<Accordion title="Severity" width="w-full">
									<InPageSearchCheckBoxGroupStandalone
										allCheckBox
										tidyTitles={true}
										filters={internalFilters['severity']}
										setFilters={(newFilters) => {
											setInternalFilters({
												...internalFilters,
												severity: newFilters,
											});
										}}
									/>
								</Accordion>
								<Divider color="border-[#DDDDDD] !border-t-[2px]" />
								<Accordion title="Incident Category" width="w-full">
									<InPageSearchCheckBoxGroupStandalone
										allCheckBox
										tidyTitles={true}
										filters={internalFilters['incidentCategory']}
										setFilters={(newFilters) => {
											setInternalFilters({
												...internalFilters,
												incidentCategory: newFilters,
											});
										}}
									/>
								</Accordion>
								<Divider color="border-[#DDDDDD] !border-t-[2px]" />
								<Accordion title="Status" width="w-full">
									<InPageSearchCheckBoxGroupStandalone
										allCheckBox
										tidyTitles={true}
										filters={internalFilters['status']}
										setFilters={(newFilters) => {
											setInternalFilters({
												...internalFilters,
												status: newFilters,
											});
										}}
									/>
								</Accordion>
								<Divider color="border-[#DDDDDD] !border-t-[2px]" />
								<Accordion title="Critical Action" width="w-full">
									<InPageSearchCheckBoxGroupStandalone
										allCheckBox
										tidyTitles={true}
										filters={internalFilters['correctiveActions']}
										setFilters={(newFilters) => {
											setInternalFilters({
												...internalFilters,
												correctiveActions: newFilters,
											});
										}}
									/>
								</Accordion>
								<Divider color="border-[#DDDDDD] !border-t-[2px]" />
								<Accordion title="Part of Body Injured" width="w-full">
									<InPageSearchCheckBoxGroupStandalone
										allCheckBox
										tidyTitles={true}
										filters={internalFilters['partOfBody']}
										setFilters={(newFilters) => {
											setInternalFilters({
												...internalFilters,
												partOfBody: newFilters,
											});
										}}
									/>
								</Accordion>
								<Divider color="border-[#DDDDDD] !border-t-[2px]" />
								<Accordion title="Injury Type" width="w-full">
									<InPageSearchCheckBoxGroupStandalone
										allCheckBox
										tidyTitles={true}
										filters={internalFilters['injuryType']}
										setFilters={(newFilters) => {
											setInternalFilters({
												...internalFilters,
												injuryType: newFilters,
											});
										}}
									/>
								</Accordion>
								<Divider color="border-[#DDDDDD] !border-t-[2px]" />
								<Accordion title="Incident Mechanism" width="w-full">
									<InPageSearchCheckBoxGroupStandalone
										allCheckBox
										tidyTitles={true}
										filters={internalFilters['incidentMechanism']}
										setFilters={(newFilters) => {
											setInternalFilters({
												...internalFilters,
												incidentMechanism: newFilters,
											});
										}}
									/>
								</Accordion>
								<Divider color="border-[#DDDDDD] !border-t-[2px]" />
								<Accordion title="Incident Agency" width="w-full">
									<InPageSearchCheckBoxGroupStandalone
										allCheckBox
										tidyTitles={true}
										filters={internalFilters['incidentAgency']}
										setFilters={(newFilters) => {
											setInternalFilters({
												...internalFilters,
												incidentAgency: newFilters,
											});
										}}
									/>
								</Accordion>
								<div className="flex-grow"></div>
							</div>
						</div>
						<div className="flex justify-between w-full mt-auto flex-none shrink border-orange-500 border-t-2">
							<Button
								className="h-16 w-[115px] mx-auto my-6 font-bold tracking-[1.92px] rounded-[29px]"
								onClick={() => {
									resetFilters();
								}}
								type="secondary"
							>
								Clear
							</Button>
							<Button
								className="h-16 w-[212px] mx-auto my-6 font-bold tracking-[1.92px] !rounded-3xl"
								onClick={() => {
									setFilters(internalFilters);
								}}
							>
								Apply
							</Button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default DashboardIncidentsSearchBar;
